'use strict';

var Types = require("../Types.bs.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");

function getElement(arg) {
  if (arg.RE_EXN_ID === Types.Exn.RestError || arg.RE_EXN_ID === Types.Exn.UnknownError || arg.RE_EXN_ID === Types.Exn.DeccoDecodeError) {
    return arg._1;
  } else if (arg.RE_EXN_ID === Types.Exn.NoTokenSpecified) {
    return "No token specified";
  } else if (arg.RE_EXN_ID === Types.Exn.NoSessionSpecified) {
    return "No session specified";
  } else if (arg.RE_EXN_ID === Types.Exn.NoFileSpecified) {
    return "No file specified";
  } else if (arg.RE_EXN_ID === Types.Exn.NoChallengeSpecified) {
    return "No challenge specified";
  } else if (arg.RE_EXN_ID === Types.Exn.NoCodeSpecified) {
    return "No code specified";
  } else if (arg.RE_EXN_ID === Types.Exn.InvalidSession) {
    return "Invalid session";
  } else if (arg.RE_EXN_ID === Types.Exn.InvalidToken) {
    return "Invalid token";
  } else if (arg.RE_EXN_ID === Types.Exn.UnexpectedUrl) {
    return "Whoops, there's nothing here";
  } else if (arg.RE_EXN_ID === Types.Exn.ExpiredToken) {
    return "Token has expired";
  } else if (arg.RE_EXN_ID === Types.Exn.Multiple) {
    console.log("Exceptions: ", arg._1);
    return "An error occured";
  } else {
    console.log("Unhandled Exception: ", arg);
    return React.createElement(React.Fragment, undefined, "Unknown Error: ", "Unhandled Exception");
  }
}

function DisplayError(Props) {
  var exn = Props.exn;
  return React.createElement("div", {
              className: "login-error alert alert-danger",
              role: "alert"
            }, exn.RE_EXN_ID === Types.Exn.Multiple ? Belt_Array.mapWithIndex(exn._1, (function (_i, innerExn) {
                      return getElement(innerExn);
                    })) : getElement(exn), React.createElement("span", undefined, React.createElement("a", {
                      href: "/help",
                      target: "_blank"
                    }, " Click for help resolving problems")));
}

var make = DisplayError;

exports.getElement = getElement;
exports.make = make;
/* react Not a pure module */

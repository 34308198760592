'use strict';

var React = require("react");
var Environment = require("../Bindings/Environment.bs.js");
var LoginPageLayout = require("../Components/LoginPageLayout.bs.js");

var accentColourBgStyle = {
  backgroundColor: Environment.accentColour
};

var accentColourTextStyle = {
  color: Environment.accentColour
};

var textStyle = {
  color: Environment.fontColour
};

function Help(Props) {
  var apiUrl = Props.apiUrl;
  var backgroundStyle = {
    backgroundColor: Environment.backgroundColour
  };
  return React.createElement("div", {
              className: "login-display row"
            }, React.createElement(LoginPageLayout.make, {
                  children: React.createElement("div", {
                        className: "row align-items-end login-form",
                        style: backgroundStyle
                      }, React.createElement("div", {
                            className: "row "
                          }, React.createElement("div", {
                                className: "col-xs-12 form-outline"
                              }, React.createElement("p", {
                                    style: textStyle
                                  }, "Sometimes codes can be delayed by 5 minutes due to anti-spam.  Please request a code and then wait without refreshing the page.  Check your spam/junk folder."), React.createElement("p", {
                                    style: textStyle
                                  }, "You may need to ask your IT department to whitelist noreply@atvero.com to receive the codes."), React.createElement("p", {
                                    style: textStyle
                                  }, "Transmittals can only be accessed by the recipients the sender specified. If you were forwarded a transmittal, please contact the original sender."), React.createElement("p", {
                                    style: textStyle
                                  }, React.createElement("a", {
                                        href: apiUrl,
                                        target: "_blank"
                                      }, "Click to open a new tab that tests if you can access the system."), React.createElement("br", undefined), "If you see an error about the site being blocked, please contact your IT department."))))
                }));
}

var fontColour = Environment.fontColour;

var accentColour = Environment.accentColour;

var make = Help;

exports.fontColour = fontColour;
exports.accentColour = accentColour;
exports.accentColourBgStyle = accentColourBgStyle;
exports.accentColourTextStyle = accentColourTextStyle;
exports.textStyle = textStyle;
exports.make = make;
/* react Not a pure module */

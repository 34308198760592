'use strict';

var Help = require("./Containers/Help.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Utils = require("./Utils/Utils.bs.js");
var React = require("react");
var FilesApp = require("./Containers/FilesApp.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Environment = require("./Bindings/Environment.bs.js");
var CollectEmail = require("./Containers/CollectEmail.bs.js");
var UnexpectedUrl = require("./Components/UnexpectedUrl.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function getViewComponent(page, atveroToken, setUserEmail, userEmail) {
  if (typeof page === "number") {
    return React.createElement(UnexpectedUrl.make, {});
  }
  switch (page.TAG | 0) {
    case /* OpenShare */0 :
        return React.createElement(CollectEmail.make, {
                    setUserEmail: (function (userEmail) {
                        return Curry._1(setUserEmail, (function (param) {
                                      return userEmail;
                                    }));
                      })
                  });
    case /* PrivateShare */1 :
        return React.createElement(FilesApp.make, {
                    apiUrl: page._0,
                    initialSessionKey: undefined,
                    initialAtveroToken: atveroToken,
                    initialUserEmail: userEmail,
                    skipUserBtnPress: page._1
                  });
    case /* PrivateShareWithSession */2 :
        return React.createElement(FilesApp.make, {
                    apiUrl: page._0,
                    initialSessionKey: page._1,
                    initialAtveroToken: atveroToken,
                    initialUserEmail: userEmail,
                    skipUserBtnPress: false
                  });
    case /* Help */3 :
        return React.createElement(Help.make, {
                    apiUrl: page._0
                  });
    
  }
}

function getCurrentPage(url, apiUrl, userEmail) {
  var match = url.path;
  var exit = 0;
  if (!match) {
    return {
            TAG: /* PrivateShare */1,
            _0: apiUrl,
            _1: false
          };
  }
  switch (match.hd) {
    case "" :
        var match$1 = match.tl;
        if (!match$1) {
          return {
                  TAG: /* PrivateShare */1,
                  _0: apiUrl,
                  _1: false
                };
        }
        switch (match$1.hd) {
          case "activate_shared_token" :
              if (match$1.tl) {
                return /* UnexpectedUrl */0;
              }
              exit = 1;
              break;
          case "shared_token" :
              if (match$1.tl) {
                return /* UnexpectedUrl */0;
              }
              exit = 2;
              break;
          default:
            return /* UnexpectedUrl */0;
        }
        break;
    case "activate_shared_token" :
        if (match.tl) {
          return /* UnexpectedUrl */0;
        }
        exit = 1;
        break;
    case "help" :
        if (match.tl) {
          return /* UnexpectedUrl */0;
        } else {
          return {
                  TAG: /* Help */3,
                  _0: apiUrl
                };
        }
    case "shared_token" :
        if (match.tl) {
          return /* UnexpectedUrl */0;
        }
        exit = 2;
        break;
    default:
      return /* UnexpectedUrl */0;
  }
  switch (exit) {
    case 1 :
        if (Belt_Option.isSome(userEmail)) {
          return {
                  TAG: /* PrivateShare */1,
                  _0: apiUrl,
                  _1: true
                };
        } else {
          return {
                  TAG: /* OpenShare */0,
                  _0: apiUrl
                };
        }
    case 2 :
        var sessionKeyOpt = Utils.getFromRouterUrl(url, "session");
        if (sessionKeyOpt !== undefined) {
          return {
                  TAG: /* PrivateShareWithSession */2,
                  _0: apiUrl,
                  _1: sessionKeyOpt
                };
        } else {
          return {
                  TAG: /* PrivateShare */1,
                  _0: apiUrl,
                  _1: false
                };
        }
    
  }
}

function Router(Props) {
  var apiUrl = Props.apiUrl;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var atveroToken = Utils.getFromRouterUrl(url, "token");
  var match = React.useState(function () {
        
      });
  var userEmail = match[0];
  var currentPage = getCurrentPage(url, apiUrl, userEmail);
  var currentViewComponent = getViewComponent(currentPage, atveroToken, match[1], userEmail);
  var bgStyle = {
    background: "url(" + Environment.backgroundImageName + ")  50% center / cover no-repeat gray"
  };
  return React.createElement("div", {
              className: "background",
              style: bgStyle
            }, currentViewComponent);
}

var make = Router;

exports.getViewComponent = getViewComponent;
exports.getCurrentPage = getCurrentPage;
exports.make = make;
/* Help Not a pure module */
